<template>
  <div class="modal-detail">
    <el-dialog  :title="isApproval && !showDetail ? '用车审批' : '用车申请详情'" :visible.sync="dialogDetailVisible" center lock-scroll  >
    <el-form >
      <el-form-item  label="用车信息" class="font-bold" :label-width="formLabelWidth"></el-form-item>
      <el-form-item  :label="item.name" v-for="(item,index) in formList" :key="index" :label-width="formLabelWidth">
        <span v-if="item.type == 'text'">{{item.value}}</span>
        <span v-if="item.type == 'date'">{{item.value}}</span>
        <!-- <el-date-picker v-if="item.type == 'date'"
                v-model="item.value"
                type="datetimerange"
                align="right"
                unlink-panels
                readonly
                range-separator="至"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              style="width:400px;">
              </el-date-picker> -->
        <div v-if="(item.type == 'tag' && item.value.length>0)">
          <el-tag :key="tag"
            v-for="tag in item.value"
            :disable-transitions="false">{{tag}}</el-tag>
        </div>
      </el-form-item>
      <el-form-item  label="用车流程" :label-width="formLabelWidth" class="font-bold">
        <div style="line-height:20px;margin:20px 10px 10px;" v-if="approvalProcess.length">
          <el-steps direction="vertical" :active="6">
            <el-step title="一级审批">
              <template #description>
                <div>
                  <el-radio label="1" disabled>{{approvalProcess[0].approver}}</el-radio>
                  <el-radio label="2" disabled :class="getFontClass(approvalProcess[0].approvalStatus)">{{status[approvalProcess[0].approvalStatus]}}</el-radio>
                  <el-radio label="2" disabled v-if="approvalProcess[0].approvalStatus !== 1">{{approvalProcess[0].approvalDate}}</el-radio>
                  <p class="reject font-red" v-if="approvalProcess[0].rejectReason">驳回原因：{{approvalProcess[0].rejectReason}}</p>
                </div>
              </template>
            </el-step>
            <el-step title="二级审批">
              <template #description>
                <div>
                  <el-radio label="1" disabled>{{approvalProcess[1].approver}}</el-radio>
                  <el-radio label="2" disabled :class="getFontClass(approvalProcess[1].approvalStatus)">{{status[approvalProcess[1].approvalStatus]}}</el-radio>
                  <el-radio label="2" disabled v-if="approvalProcess[1].approvalStatus !== 1">{{approvalProcess[1].approvalDate}}</el-radio>
                  <p class="reject font-red" v-if="approvalProcess[1].rejectReason">驳回原因：{{approvalProcess[1].rejectReason}}</p>
                </div>
              </template>
            </el-step>
            <el-step title="派车人">
              <template #description>
                <div>
                  <el-tag v-for="(item,index) in approvalProcess[2].approver.split(',')" :key="index">{{item}}</el-tag>
                 <el-radio label="2" disabled :class="getFontClass(approvalProcess[2].approvalStatus)">{{carStatus[approvalProcess[2].approvalStatus]}}</el-radio>
                 <el-radio label="2" disabled>{{approvalProcess[2].approvalDate}}</el-radio>
                </div>
              </template>
            </el-step>
            <el-step title="用车结束" v-if="result.approvalStatus === 5">
               <template #description>
                <div>
                  <el-radio label="1" disabled>{{result.carUser}}</el-radio>
                   <el-radio label="1" disabled class="font-green">{{options[result.approvalStatus]}}</el-radio>
                  <el-radio label="1" disabled>{{result.returnCarDate}}</el-radio>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
        <p class="reject font-red" v-if="result.approvalStatus === 6">已取消用车  <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></span>{{result.updatedDate}}</p>
      </el-form-item>
      <div v-if="isApproval && !showDetail">
          <el-divider ></el-divider>
            <el-form-item  label="审批意见" :label-width="formLabelWidth" class="font-bold">
              <div>
                <div class="text-center">
                  <el-radio v-model="radio" class="agree" style="margin-left:-100px" label="2" border>通过</el-radio>
                  <el-radio v-model="radio" label="3" class="noagree agree" border>驳回</el-radio>
                </div>
                <el-input style="margin-top:10px" v-show="radio == '3'" maxlength="15" v-model="reason" placeholder="请输入驳回原因"></el-input>
              </div>
            </el-form-item>
      </div>
      <div v-if="isReturnCar && !showDetail">
          <el-divider ></el-divider>
          <el-form-item  label="派车" class="font-bold" :label-width="formLabelWidth"></el-form-item>
          <el-form-item style="margin-top:10px" label="车牌号" :label-width="formLabelWidth" >
              <el-input   v-model="carParam.number" maxlength="10" placeholder="请输入车牌号"></el-input>
          </el-form-item>
          <el-form-item style="margin-top:10px" label="取车地点" :label-width="formLabelWidth" >
             <addressSelect  @changeDetailAddress="changeDetailAddressStart" @addressInfo='addressInfoStart' :address="labAddress" ></addressSelect>
          </el-form-item>
          <el-form-item style="margin-top:10px" label="派车备注" :label-width="formLabelWidth" >
              <el-input   v-model="carParam.remark" maxlength="15" placeholder="请输入派车备注(可不填)"></el-input>
          </el-form-item>
      </div>
    </el-form>
 
  <div slot="footer" v-if="(isApproval || isReturnCar) && !showDetail " class="dialog-footer">
    <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
  </div>
  
</el-dialog>
  </div>
</template>
<script>
import addressSelect from './address.vue';
export default {
  props: {
    id:{ type: String },
    value:{ type: Boolean ,default: ()=>false },
    showDetail:{ type: Boolean ,default:()=> true },
    isApproval:{ type: Boolean, default: ()=> false},
    isReturnCar:{ type: Boolean, default: ()=> false}
  },
  components:{
    addressSelect,
  },
  name:'modal-input',
  data() {
    return {
      dialogDetailVisible: this.value,
      formLabelWidth: '100px',
      form: {
        company: '',
        name: '',
        region: ''
      },
      inputVisible: false,
      inputValue: '',
      formList:[
        { name: '用车单位:', value: '', type: 'text'},
        { name: '用车人:', value: '', type: 'text'},
        { name: '乘车人:', value: [""], type: 'tag', btn: '+添加', Number: 4},
        { name: '用车事由:', value:'', type: 'text'},
        { name: '用车时间:', value:'', type: 'date'},
        { name: '预计归还时间:', value:'', type: 'date'},
        { name: '用车起点:', value:'', type: 'text'},
        { name: '用车止点:', value:'', type: 'text'},
        { name: '用车备注:', value:'', type: 'text'}
      ],
      nameList:['department','carUser',['passengers'],'carCause','startDate','endDate','startingPoint','endPoint','remark'],
      radio: "2",
      reason: '',
      approvalProcess:[],
      options:{
        0:'未开始',
        1:'审批中',
        2:'已驳回',
        3:'派车中',
        4:'已派车',
        5:'已还车',
        6:'已取消'
      },
      status:{
        0:'未开始',
        1:'审批中',
        2:'已通过',
        3:'已驳回' ,
        4:' 已取消'
      },
      carStatus:{
        0:'未开始',
        1:'派车中',
        2:'已派车',
        3:'已驳回' ,
        4:' 已取消'
      },
      carParam:{
        number:'',
        address:'',
        remark:''
      },
      result:{ },
      address:[
        {
          name:'选择派车地点',
          value:''
        },{
          name:'填写详细派车地点',
          value:''
        }
      ],
      labAddress: { 
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
      },
    }
  },
  mounted() {
   
  },
  methods:{
    //  修改地址
    addressInfoStart(val) {
      this.address[0].value  = val.reduce(function(prev, cur) {
            return  prev + cur.name;
        },'') 
    },
    changeDetailAddressStart(val){
      this.address[1].value = val;
    },
    getFontClass(status){
      const  colorClass = status == '1'?'font-blue':status=='2'?'font-green':status == '3'?'font-red':'';
      return colorClass;
    },
    async onSubmit() {
      let api = '';
      let param = {};
      let errorMsg = '';
      if(this.isApproval) {
        api = this.$fetchApi.nanfanCarApproval.api  // 审核}
        param = {
          "approvalId": this.approvalProcess[0].approvalId,
          "approvalStatus": Number(this.radio), // 通过,驳回
          "rejectReason": this.reason
        }
      }
      if(this.isReturnCar) {
        for(let i = 0 ; i < this.address.length; i++ ){
          if(this.address[i].value == ""  && this.address[i].name != '填写详细派车地点'){
            this.$message.error(`${this.address[i].name}`);
            return false;
          }
        }
        this.carParam.address = this.address[0].value + this.address[1].value;
        if (!this.carParam.number) errorMsg = "请输入车牌号";
        
        if(errorMsg){
          this.$alert(errorMsg);
          return;
        }
        api = this.$fetchApi.nanfanCarSendCars.api // 派车
        param = {
        "approvalId": this.approvalProcess[0].approvalId,
        "numberPlates": this.carParam.number,
        "pickCarPoint": this.carParam.address,
        "sendCarRemark": this.carParam.remark
        }
      } 
      const data = await this.$fetchData.fetchPost(param, api, "json");
      this.dialogDetailVisible = false;
      if(data.code === '200'){
         this.$emit('refresh','1');
        this.$message.success('操作成功')
      }else{
        this.$message.error(data.message)
      }
    },
    handleClose(tag,item) {
      item.value.splice(item.value.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
    },
    async initData(){
      const api = this.$fetchApi.nanFanCarApplyDetail.api;
      const data = await this.$fetchData.fetchPost({}, `${api}/${this.id}`, "json");
      if(data.code === '200'){
        const result = data.result;
        this.result = result;
        this.approvalProcess = result.processDetails;
        this.formList.forEach((item,index)=>{
          item.value = result[this.nameList[index]]
          if(item.name !== '用车时间:'){
            if(typeof(this.nameList[index]) == "string"){
              item.value = result[this.nameList[index]]
            }else{
              let value = result[this.nameList[index]];
              item.value = value.split(',')
              
            }
          }else{
            //  时间单独处理
            // item.value = [];
            // item.value.push(result['startDate']);
            // item.value.push(result['endDate']);
          }
          // if(item.name == '预计归还时间:'){
          //   item.value = result[this.nameList[index]]
          // }
          
        })

        console.log("this.formList",this.formList)
        this.formList
      }
    },
    handleInputConfirm(item) {
      let inputValue = this.inputValue;
      if (inputValue) {
        item.value.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  },
  watch:{
    value(newValue) {
      this.dialogDetailVisible = newValue;
    },
    id(newValue) {
      if(!newValue) return false;
      this.initData();
    },
    dialogDetailVisible(newValue){
      this.$emit('input', newValue);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.text-center{
  text-align: center;
}
.reject{
  padding-left: 10px;
  line-height: 26px;
}
/deep/.el-step.is-vertical .el-step__main{
  height: 70px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  width: 900px!important;
  padding-bottom: 30px;
}
.font-red{
  color: #f4511e;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #f4511e;
  }
}
.font-green {
  color: #67c23a;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #67c23a;
  }
}
.font-blue {
  color: #409eff;
  /deep/.el-radio__input.is-disabled+span.el-radio__label{
    color: #409eff;
  }
}
.agree{
  text-align: center!important;
  height: 30px;
  line-height: 6px;
}
.agree.is-bordered.is-checked{
  text-align: center;
  background-color: #85ce61 ;
  border-color: #85ce61;
  /deep/.el-radio__input.is-checked+.el-radio__label{
  color: #fff!important;
  }
}
.noagree.is-bordered.is-checked{
  border-color: #f4511e;
  background-color: #f4511e;
  /deep/.el-radio__input.is-checked+.el-radio__label{
  color: #fff!important;
  }
}
.modal-detail .el-radio{
  width: 80px;
  text-align: left;
}
.modal-detail /deep/.el-radio__input {
  display: none!important;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px ;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:0px
}
.font-bold{
  font-size: 18px;
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 0px;
} 
.el-tag{
  margin: 4px 10px;
}
.el-divider--horizontal{
  margin: 10px 0px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
<template>
  <div class="nanfanregister">
    <bread-crumb :breadList="breadList" />
    <div class="nanfanregister-content auto-center">
      <!-- <div class="nanfanregister-content-title">南繁备案情况</div> -->
       <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <p v-for="(nav, index) in tabsNav"
          :key="index">
           <a class="nf-tabs-nav-label" v-show="nav.status"
            :class="{'is-active': activeNav === index}"
            @click="activeNav = index"> 
            <i :class="nav.iconClass" class="icon"></i>
            <span>{{nav.name}}</span>
          </a>
        </p>
       
      </div>
      <div class="nf-tabs-content">
        <!--  第一个tab--start -->
        <apply v-if="activeNav === 0" />
        <manage v-if="activeNav === 1" />
        <statistics v-if="activeNav === 2" />
        <div>
           
        
        </div>
        <!--  第一个tab--end -->
        <!--  第二个tab--end -->
        <!-- <div v-show="activeNav === 1">
            曾经的你
        </div> -->
        <!--  第二个tab--end -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "@/component/breadcrumb/breadcrumb.vue";
import apply from './component/apply.vue'
import manage from './component/manage.vue'
import statistics from './component/statistics.vue'
import pageCut from "@/component/pagination/index.vue";
export default {
  data() {
    return {
      activeNav: 0,
      breadList: [{ name: "南繁用车" }],
      tabsNav: [
        {name: "用车申请", iconClass:'icon-processing',status:true,},
        {name: "用车管理", iconClass:'icon-draft',status:this.isAdopt},
        {name: "用车统计", iconClass:'icon-draft',status:true}
      ],
      isAdopt:false
    };
  },
  components: {
    breadCrumb,
    pageCut,
    apply,
    manage,
    statistics
  },
  created() {
    this.getIsAdopt();
  },
  methods: {
    async getIsAdopt() {
      const api = this.$fetchApi.nanFanCarisAdopt.api;
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if(data.code === '200'){
        this.tabsNav[1].status = this.isAdopt = data.result;
      }
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__item {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
}
/deep/ .el-tabs__item.is-active {
  color: #265ae1;
}
/deep/ .el-tabs__active-bar {
  height: 3px;
  background: #265ae1;
}
//  左侧导航
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_db.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
<template>
  <div class="manage">
    <el-row :gutter="20" class="user">
      <el-col :span="6"><div class="user-content">
        <p class="count">{{useStatistics.useCount||'0'}}</p>
        <p>累计用车次数</p>
        </div></el-col>
      <el-col :span="6"><div class="user-content">  
        <p class="count">{{getDate(useStatistics.countUseTime)}}</p>
        <p>累计用车时长</p></div></el-col>
      <el-col :span="6"><div class="user-content">
         <p class="count">{{useStatistics.useInCount||'0'}}辆</p>
        <p>使用中车辆</p></div></el-col>
      <el-col :span="6"><div></div></el-col>
    </el-row>
     <div class="nf-tabs-filter-form">
          <el-form :inline="true" class="filter-form">
            <el-form-item :label="item.name" v-for="(item,index) in filterForm" :key="index">
              <el-select class="form-row" v-model='item.value' :placeholder ="`请选择 ${item.name}`" v-if="item.type == 'select'">
                <el-option :label="o" :value="i" v-for="(o,i) in item.options" :key="i"></el-option>
              </el-select>
              
              <el-input v-if="item.type == 'text'" class="form-row" v-model="item.value" :placeholder="`请输入 ${item.name}`"></el-input>
              <el-date-picker v-if="item.type == 'date'"
                v-model="item.value"
                type="daterange"
                align="right"
                unlink-panels
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
         
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button class="pull-right export-btn" type="primary" @click="exportTable">导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange" empty-text="暂无用车记录">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column width="100" label="用车人" prop="carUser" align="center"></el-table-column>
          <el-table-column label="用车事由" prop="carCause" align="center"></el-table-column>
          <el-table-column label="用车时间" prop="startDate" align="center"></el-table-column>
          <el-table-column label="用车状态" align="center">
            <template slot-scope="scope">
              <div>
                {{options[scope.row.approvalStatus]}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="车牌号" prop="numberPlates" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-tickets"
                @click="handleEdit(scope.$index, scope.row,1)"
              >详情</el-button>
            </template>
            </el-table-column>
        </el-table>
        <page-cut :total="total" @pageChange="pageChange" :currentPage="pageNum" :pageSize="pageSize"></page-cut>
        <modal-detail v-model="dialogDetailVisible" :id="id" :showDetail= "showDetail"  />
  </div>
</template>
<script>
import modalDetail from './modal-detail'
import pageCut from "@/component/pagination/index.vue";
import { downlodFile } from '@/utils/utils.js'
export default {
  name:'apply',
  data(){
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      applyState: '2',
      filterForm:[
        { name: '用车人', value: '', type: 'text' },
        { name: '用车状态', value: '', type: 'select', options:['全部','审批中','已驳回','派车中','已派车','已还车','已取消']},
        { name: '车牌号', value: '', type: 'text' },
        { name: '用车时间', value: [], type: 'date' },
      ],
      showDetail: true,
      pageNum: 1,
      pageSize: 10,
      id:'',
      dialogDetailVisible: false,
      total: 1,
      options:{
        0:'未开始',
        1:'审批中',
        2:'已驳回',
        3:'派车中',
        4:'已派车',
        5:'已还车',
        6:'已取消'
      },
      tableData:[],
      useStatistics:{},
      multipleSelection:[]
    }
  },
  components:{
    'modal-detail': modalDetail,
    pageCut
  },
  created(){
    this.initData();
    this.getUseStatistics();
  },
  methods:{
    downlodFile,
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(index,el,i){
      // 查看详情
      if( i === 1 ) {
        this.id = el.id;
        this.showDetail = true;  // 是否仅仅展示详情
        this.dialogDetailVisible = true;
      }else if( i === 2 ){
        // 取消用车
        this.id = el.id;
        this.showDetail = false; // 为false 标识可以编辑审批
        this.dialogDetailVisible = true;
      }
    },
    // 导处表格
    async exportTable() {
      if(!this.multipleSelection.length ) {
        this.$message.error('请选择要导出的数据');
        return;
      }
      const str = this.multipleSelection.reduce(function(prev, cur) {
            return  prev + `ids=${cur.id}&`;
        },'')
      const api = this.$fetchApi.nanFanCarExportExpress.api;
      const data = await this.$fetchData.fetchPost({}, `${api}?${str}`, 'arraybuffer');
      this.downlodFile(data,'统计数据.xls')
    },
    onSubmit:function() {
      this.pageNum = 1;
      this.initData();
    },
    pageChange(num){
      this.pageNum =Number(num.page_index);
      this.initData();
    },
    getDate(time){
      time = time || 0;
      if(time <  24) return  time + '小时';
      return Math.floor(time/24) + '天' + time%24 +'小时'
    },
    async initData() {
      this.filterForm[3].value = this.filterForm[3].value || [];
      const api = this.$fetchApi.nanfanCarStatisticsList.api;
      const param = {
        "approvalStatus": this.filterForm[1].value == '0'?'':this.filterForm[1].value,
        "numberPlates": this.filterForm[2].value,
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
        "keyWords": this.filterForm[0].value,
        "returnCarEnd": "",
        "returnCarStart": "",
        "useCarEnd": this.filterForm[3].value[1] || '',
        "useCarStart": this.filterForm[3].value[0] || ''
      }
      const data = await this.$fetchData.fetchPost(param, api, "json");
      if(data.code !== '200'){
        this.$message.error(data.message)
        return false;
      }
      this.tableData = data.result.list;
      this.total = data.result.total
    },
    async getUseStatistics() {
      const api = this.$fetchApi.nanfanCarUseStatistics.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if(data.code == '200'){
        this.useStatistics = data.result;
      }
    }
  },
  watch:{
  }
}
</script>
<style lang="less" scoped>
.text-center {
  text-align: center;
}
.pull-right {
  float: right;
}
.export-btn {
  margin-right: 40px;
}
/deep/.el-radio-button__inner {
  width: 120px;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  border-color: #67c23a;
  background-color:#67c23a ;
}
/deep/.el-radio-button__inner:hover {
    color: #67c23a ;
}
.user-content {
    width: 80%;
    margin: 0 auto;
    height: 90px;
    display: grid;
    font-size: 14px;
    border: 1px solid #c0c4cc;
    color: #666;
    border-radius: 2px;
    place-content: center;
    text-align: center;
  .count{
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    line-height: 40px;
  }
}
.manage{
  padding-top: 20px;
  background: rgba(249, 251, 255, 0.99);
  min-height: 400px;
  .filter-form{
    padding: 10px 0px 0px 10px;
   
  }
  &-content {
    box-sizing: border-box;
    padding: 0 20px;
    &-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-indent: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(51, 128, 254, 1);
        border-radius: 50%;
      }
    }
    /deep/ .el-table td {
      border: none;
      padding: 2px 0;
      // margin: 5px 0;
      // background-color:#fff;
      // box-sizing: border-box;
    }
    &-btn {
      text-align: center;
      height: 150px;
      line-height: 50px;
    }
    &-item {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      span {
        margin-right: 35px;
        font-size: 18px;
      }
      /deep/ .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      /deep/ .el-icon-arrow-down {
        font-size: 16px;
      }
    }
  }
  
}

</style>
<template>
  <div>
    <el-dialog title="用车申请" :visible.sync="dialogFormVisible" center :close-on-click-modal="false" lock-scroll top="10vh">
    <el-form >
      <el-form-item  label="用车信息" class="font-bold" :label-width="formLabelWidth"></el-form-item>
      <el-form-item  :label="item.name" v-for="(item,index) in formList" :key="index" :label-width="formLabelWidth">
        <el-input v-if="item.type == 'text'" :disabled="item.disabled" :placeholder ="`请输入 ${item.name}`" v-model="item.value" autocomplete="off" :maxlength="item.maxLength"></el-input>
        <addressSelect v-if="item.type == 'address' && index === 6" @changeDetailAddress="changeDetailAddressStart"
         :isRefresh="isRefresh" @addressInfo='addressInfoStart' :address="labAddress" ></addressSelect>
        <addressSelect v-if="item.type == 'address' && index === 7" @changeDetailAddress="changeDetailAddressEnd" 
        :isRefresh="isRefresh"  @addressInfo='addressInfoEnd' :address="labAddress" ></addressSelect>
        <!-- <el-date-picker v-if="item.type == 'date'"
          v-model="item.value"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="wdith:800px;">
        </el-date-picker> -->
        <el-date-picker v-if="item.type == 'date'"
          v-model="item.value"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="选择日期时间">
        </el-date-picker>
        <div v-if="item.type == 'tag'">
          <el-tag :key="tag"
            closable
            v-for="tag in item.value"
            :disable-transitions="false"
            @close="handleClose(tag,item)">{{tag}}</el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            width="120"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(item)"
            @blur="handleInputConfirm(item)"
          >
          </el-input>
           <el-button class="button-new-tag" size="small" @click="showInput">{{item.btn}}</el-button>
        </div>
      </el-form-item>
      <el-form-item  label="用车流程" :label-width="formLabelWidth" class="font-bold">
        <div style="height: 200px;line-height:20px;margin:20px 10px 10px;">
          <el-steps direction="vertical" :active="3">
            <el-step title="一级审批" >
              <template #description>
                <div>
                    <el-radio  v-for="(item ,index) in approvalProcess[0]" :key="index" v-model="radio1" :label="item.approverId">{{item.approver}}</el-radio>
                </div>
              </template>
            </el-step>
            <el-step title="二级审批">
              <template #description>
                <div>
                <div>
                  <el-radio v-for="(item ,index) in  approvalProcess[1]" :key="index" v-model="radio2" :label="item.approverId">{{item.approver}}</el-radio>
                </div>
                </div>
              </template>
            </el-step>
            <el-step title="派车人">
              <template #description>
                <div>
                  <el-tag v-for="(item ,index) in approvalProcess[2]" :key="index">{{item.approver}}</el-tag>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </el-form-item>
    </el-form>
  <el-divider ></el-divider>
  <div slot="footer" class="dialog-footer">
    <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
  </div>
</el-dialog>
  </div>
</template>
<script>
import addressSelect from './address.vue';
import {  Message } from "element-ui";
export default {
  name:'modal-input',
  props: ['value','userName'],
  components:{
    addressSelect,
  },
  data() {
    return {
      dialogFormVisible: this.value,
      formLabelWidth: '100px',
      form: {
        company: '',
        name: '',
        region: ''
      },
      isRefresh:false,
      inputVisible: false,
      inputValue: '',
     
      formList:[
        { name: '用车单位', value: '南繁管理局', type: 'text',disabled:true},
        { name: '用车人', value: '', type: 'text',disabled:true},
        { name: '乘车人', value: [], type: 'tag', btn: '+添加', Number: 4},
        { name: '用车事由', value:'', type: 'text',require:true, maxLength:200},
        { name: '用车时间', value:'', type: 'date',require:true},
        { name: '预计归还时间', value:'', type: 'date',require:true},
        { name: '用车起点', value:'', type: 'address'},
        { name: '用车止点', value:'', type: 'address'},
        { name: '用车备注', value:'', type: 'text',require:true, maxLength:200}
      ],
      radio1:'',
      radio2: '',
      labAddress: { 
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
      },
      address:[
        {
          name:'选择用车起点',
          value:''
        },
        {
          name:'填写详细用车起点',
          value:''
        },
         {
          name:'选择用车止点',
          value:''
        },
        {
          name:'填写详细用车起点',
          value:''
        }
      ],
      approvalProcess:[[],[],[]]
    }
  },
  created() {
    this.getApprover();
  },
  methods:{
     async apply() {
        const param = {};
        this.formList[6].value = this.address[0].value+this.address[1].value; // 起点
        this.formList[7].value = this.address[2].value+this.address[3].value; // 终点
        param.department = this.formList[0].value; // 用车部门
        param.carUser = this.formList[1].value; // 用车人
        param.passengers = this.formList[2].value.toString(); // 乘车人
        param.carCause = this.formList[3].value; // 用车原因
        param.startDate = this.formList[4].value;
        param.endDate = this.formList[5].value;;
        param.startingPoint = this.formList[6].value;
        param.endPoint = this.formList[7].value; 
        param.remark = this.formList[8].value; // 用车备注

        console.log("1112323",this.approvalProcess)
        let name1 = this.approvalProcess[0].map(item=>{
          if(item.approverId == this.radio1){
            return item.approver
          }
        }) 
        let name2 = this.approvalProcess[1].map(item=>{
          if(item.approverId == this.radio2){
            return item.approver
          }
        }) 
        param.approvalProcess = [
          {
            "approvalLevel": 1,
            "approver":name1.toString().replace(',',''),
            "approverId": this.radio1
          },
          {
            "approvalLevel": 2,
            "approver": name2.toString().replace(',',''),
            "approverId":this.radio2
          },
          {
            "approvalLevel": 3,
            // "approver": this.approvalProcess[2][0].approver+','+ this.approvalProcess[2][0].approver,
            "approver": this.approvalProcess[2][0].approver,
            "approverId": ""
          }
        ]
        const api = this.$fetchApi.nanFanCarApply.api;
        const data = await this.$fetchData.fetchPost(param, api, "json");
        if(data.code !== '200'){
            this.$message.error(data.message || data.result.message);
            return false;
        }
        this.$emit('input',false);
        this.resetForm();
        this.$emit('refresh','1');
        this.$message.success('申请成功!')
    },
    addressInfoStart(val) {
      this.address[0].value  = val.reduce(function(prev, cur) {
        return  prev + cur.name;
      },'') 
      console.log(this.address)
    },
    addressInfoEnd(val) {
      this.address[2].value  = val.reduce(function(prev, cur) {
        return  prev + cur.name;
      },'')
      console.log(this.address)
    },
    changeDetailAddressStart(val){
      // alert(33333)
      this.address[1].value = val;
      console.log(this.address)
    },
    changeDetailAddressEnd(val){
      // alert(44444)
      this.address[3].value = val;
      console.log(this.address)
    },
    async getApprover() {
      const api = this.$fetchApi.nanFanCarApprover.api;
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if(data.code === '200'){
        data.result.forEach(item => {
          let index = Number(item.approvalLevel) - 1
            this.approvalProcess[index].push(item);
        });

        console.log("111232388888",this.approvalProcess)
        console.log("111232366666",this.approvalProcess[2][0].approver)
        console.log("111232377777",this.approvalProcess[2][0].approver)

        // this.approvalProcess[2][0].approver = this.approvalProcess[2][0].approver || '蒙永辉';
        // this.approvalProcess[2][1].approver = this.approvalProcess[2][0].approver || '陈振'
      }
    },
    onSubmit(){
      console.log("this.formList",this.formList)
      for(let i = 0 ; i < this.formList.length; i++ ){
        if( this.formList[i].value == "" &&  this.formList[i].require){
          this.$message.error(`请输入${this.formList[i].name}`);
          return false;
        }
        var oDate1 = new Date(this.formList[4].value);
        var oDate2 = new Date(this.formList[5].value);
        if(oDate1 && oDate2){
          if(oDate1.getTime() > oDate2.getTime()){
            this.$message.error('归还时间不能小于用车时间');
            return false;
          }
        }
      }
      for(let i = 0 ; i < this.address.length; i++ ){
        console.log(this.address)
        if(this.address[i].value == "" && this.address[i].name != '填写详细用车起点'){
          this.$message.error(`${this.address[i].name}`);
          return false;
        }
      }
      if(this.radio1 == "" || this.radio2 == ""){
       this.$message.error('请选择审批人');
       return false; 
      }
      this.apply();
    },
    resetForm() {
      this.formList.forEach(item => {
        if(!item.disabled){
          item.value  = typeof(item.value) == "string"?"":[]
        }
      })
      this.radio1 = this.radio2 = ''
      this.isRefresh = true;
    },
    handleClose(tag,item) {
      item.value.splice(item.value.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
    },
    handleInputConfirm(item) {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (item.value.length <= 3) {
          item.value.push(inputValue);
        } else {
          this.$message.error('最多只能添加4个乘车人');
        }
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  },
  watch:{
    value(newVal){
      this.dialogFormVisible = newVal;
    },
    userName(newVal) {
       this.formList[1].value = newVal;
    },
    dialogFormVisible(newVal){
      this.$emit('input', newVal);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  width: 960px!important;
}
/deep/.el-radio{
  width: 80px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:10px
}
.font-bold{
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 10px;
} 
.el-tag{
  margin: 4px 10px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-bottom: 0px;
}
</style>
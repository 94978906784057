var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news"},[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"海南要闻","name":"0"}},[_c('div',{staticClass:"news_contain"},[_c('div',{staticClass:"news_content"},[(_vm.newsX.length > 0)?_c('ul',_vm._l((_vm.news),function(item,index){return _c('router-link',{key:index,staticClass:"flex_public",attrs:{"tag":"li","to":{name:'plocydetail',query:{
              idx:_vm.activeName,
              id:item.informationId,
            }}}},[_c('p',{staticClass:"news_text ellipsis"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"news_time"},[_vm._v(_vm._s(item.releaseDate))])])}),1):_vm._e()])])]),_c('el-tab-pane',{attrs:{"label":"最新政策","name":"1"}},[_c('div',{staticClass:"news_contain"},[_c('div',{staticClass:"news_content"},[(_vm.newsX.length > 0)?_c('ul',_vm._l((_vm.newsX),function(item,index){return _c('router-link',{key:index,staticClass:"flex_public",attrs:{"tag":"li","to":{name:'plocydetail',query:{
              idx:_vm.activeName,
              id:item.informationId,
            }}}},[_c('p',{staticClass:"news_text ellipsis"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"news_time"},[_vm._v(_vm._s(item.releaseDate))])])}),1):_vm._e()])])]),_c('el-tab-pane',{attrs:{"label":"通知公告","name":"2"}},[_c('div',{staticClass:"news_contain"},[_c('div',{staticClass:"news_content"},[(_vm.notice.length > 0)?_c('ul',_vm._l((_vm.notice),function(item,index){return _c('router-link',{key:index,staticClass:"flex_public",attrs:{"tag":"li","to":{name:'plocydetail',query:{
              idx:_vm.activeName,
              id:item.informationId,
            }}}},[_c('p',{staticClass:"news_text ellipsis"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"news_time"},[_vm._v(_vm._s(item.releaseDate))])])}),1):_vm._e()])])])],1),_c('router-link',{staticClass:"more",attrs:{"tag":"span","to":{
    name:`newsoption${_vm.activeName}`,
    query:{
      idx:_vm.activeName
    }
  }}},[_vm._v("更多")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="news">
    <el-tabs v-model="activeName">
      <el-tab-pane label="海南要闻" name="0">
        <div class="news_contain">
          <div class="news_content">
            <ul v-if='newsX.length > 0'>
              <router-link
                tag="li"
                :to="{name:'plocydetail',query:{
                idx:activeName,
                id:item.informationId,
              }}"
                class="flex_public"
                v-for="(item,index) in news"
                :key="index"
              >
                <p class="news_text ellipsis">{{item.title}}</p>
                <span class="news_time">{{item.releaseDate}}</span>
              </router-link>
            </ul>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="最新政策" name="1">
        <div class="news_contain">
          <div class="news_content">
            <ul v-if='newsX.length > 0'>
              <router-link
                tag="li"
                :to="{name:'plocydetail',query:{
                idx:activeName,
                id:item.informationId,
              }}"
                class="flex_public"
                v-for="(item,index) in newsX"
                :key="index"
              >
                <p class="news_text ellipsis">{{item.title}}</p>
                <span class="news_time">{{item.releaseDate}}</span>
              </router-link>
            </ul>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="通知公告" name="2">
        <div class="news_contain">
          <div class="news_content">
            <ul v-if='notice.length > 0'>
              <router-link
                tag="li"
                :to="{name:'plocydetail',query:{
                idx:activeName,
                id:item.informationId,
              }}"
                class="flex_public"
                v-for="(item,index) in notice"
                :key="index"
              >
                <p class="news_text ellipsis">{{item.title}}</p>
                <span class="news_time">{{item.releaseDate}}</span>
              </router-link>
            </ul>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <router-link
      class="more"
      tag="span"
      :to="{
      name:`newsoption${activeName}`,
      query:{
        idx:activeName
      }
    }"
    >更多</router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 最新政策: 3373f13e83b74f12a213a53db20a6657
      // 通知公告: abb9308a63f64190b55633b83cc27e12
      // 最新要闻: d1707e23348143fb99537c9bddffcdb3,
      columnIds: {
        news: "d1707e23348143fb99537c9bddffcdb3",
        newsX: "3373f13e83b74f12a213a53db20a6657",
        notice: 'abb9308a63f64190b55633b83cc27e12'
      },
      activeName: "0",
      news: [],
      newsX: [],
      notice: []
    };
  },
  async created() {
    await this.getData("news");
    await this.getData("newsX");
    await this.getData("notice");
  },
  methods: {
    async getData(opt) {
      const api = this.$fetchApi.dynamicNewsList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: 1,
          pageSize: 8,
          columnId: this.columnIds[opt]
        },
        api,
        "json"
      );
      if (data.code === "200") {
        this[opt] = data.result.rows;
      }
    }
  }
};
</script>
<style scoped>
.news >>> .el-tabs__content {
  overflow: inherit;
}
.news >>> .el-tabs__item {
  width: 150px;
  height: 44px;
  line-height: 44px;
  font-family: MicrosoftYaHei;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 3px;
  color: #757575;
  text-align: center;
  box-sizing: border-box;
}
.news >>> .el-tabs__item.is-active {
  color: #265ae1;
}
</style>
<style lang="less" scoped>
.news {
  width: 47.5%;
  position: relative;
  .news_contain {
    width: 100%;
    height:320px;
    display: flex;
    .icon_left {
      width: 324px;
      margin-right:17px;
      img {
        width: 100%;
        height: 230px;
      }
      p {
        width: 100%;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 0px;
        color: #333333;
        cursor: pointer;
        text-align: center;
        &:hover {
          color: #265ae1;
          text-decoration: underline;
        }
      }
    }
    .news_content {
      width: 100%;
      padding: 0 5px 0 25px;
      box-sizing: border-box;
      ul {
        width: 100%;
        margin: 0;
        li {
          position: relative;
          &::before {
            position: absolute;
            left: -16px;
            top: 16px;
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background-color: #bfbfbf;
            border-radius: 50%;
          }
          width: 100%;
          .news_text {
            width: 75%;
            font-family: MicrosoftYaHei;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            margin: 5px 0;
            cursor: pointer;
            &:hover {
              color: #265ae1;
              text-decoration: underline;
            }
          }
          .news_time {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #999999;
            cursor: pointer;
            &:hover {
              color: #333;
            }
          }
        }
      }
    }
  }
  .more {
    position: absolute;
    right: 1%;
    top: 10px;
    width: 35px;
    height: 32px;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: 0px;
    color: #999999;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="apply">
     <div class="nf-tabs-filter-form">
        <div class="nanfanregister-content-btn" style="padding:20px 10px;">
          <el-button type="primary" size="medium" @click="dialogFormVisible = true" icon="el-icon-plus">新增用车申请</el-button>
        </div>
          <el-form :inline="true" class="filter-form">
            <el-form-item :label="item.name" v-for="(item,index) in filterForm" :key="index">
              <el-select class="form-row" v-model='item.value' :placeholder ="`请选择 ${item.name}`" v-if="item.type == 'select'">
                <el-option :label="o" :value="i" v-for="(o,i) in item.options" :key="i"></el-option>
              </el-select>
              
              <el-input v-else class="form-row" v-model="item.value" :placeholder="`请输入 ${item.name}`"></el-input>
            </el-form-item>
         
            <el-form-item>
              <el-button type="primary" @click="getSearch">查询</el-button>
            </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%;" empty-text="暂无申请记录">
          <el-table-column label width="10" align="left">
            <div
              style="width:3px;height:50px;margin-left:-10px;background:rgba(51,128,254,1);border-radius:2px;"
            ></div>
          </el-table-column>
          <el-table-column width="100" label="乘/用车人"  align="center">
            <template slot-scope="scope">
              <div class="overflow-text">
              {{scope.row.carUser}}{{scope.row.passengers !==""?',':''}}{{scope.row.passengers}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用车事由" prop="carCause" align="center"></el-table-column>
          <el-table-column label="用车时间" prop="startDate" align="center"></el-table-column>
          <el-table-column label="用车状态" align="center">
            <template slot-scope="scope">
              <div>
                {{options[scope.row.approvalStatus]}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-tickets"
                @click="handleEdit(scope.$index, scope.row,1)"
              >详情</el-button>
              <el-button
                size="mini"
                v-if="scope.row.approvalStatus === 1 || scope.row.approvalStatus === 3 "
                type="danger"
                @click="handleEdit(scope.$index, scope.row,2)"
                icon="el-icon-delete"
              >取消用车</el-button>
              <el-button
                size="mini"
                v-if="scope.row.approvalStatus === 4"
                type="success"
                @click="handleEdit(scope.$index, scope.row,3)"
                icon="el-icon-s-unfold"
              >还车</el-button>
              <!-- <el-button
                size="mini"
                v-if="scope.row.approvalStatus === 6"
                type="info"
                disabled
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row,3)"
              >已取消</el-button> -->
              <el-button
                size="mini"
                v-if="scope.row.registerStatus === 2"
                type="text"
                style="text-decoration:underline"
                @click="handleEdit(scope.$index, scope.row,4)"
              >请填写登记信息</el-button>
            </template>
          </el-table-column>
      </el-table>
        <page-cut :total="total" @pageChange="pageChange" :currentPage="pageNum" :pageSize="pageSize"></page-cut>
        <modal-form v-model="dialogFormVisible" @refresh="getSearch" :userName="userInfo.userName" ></modal-form>
        <modal-detail  v-model="dialogDetailVisible" :id="id" ></modal-detail>
        <el-dialog title="还车" width="65%" :visible.sync="reCar.state" style="z-index:1111">
          <el-form class="reCar" >
            <el-form-item label="车牌号" label-width="120">
              <el-input v-model="reCar.numberPlates" readonly autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item style="margin-top:10px" label="还车地点" label-width="120" >
             <addressSelect  @changeDetailAddress="changeDetailAddressStart" @addressInfo='addressInfoStart' :address="labAddress" :isEdit="false" ></addressSelect>
            </el-form-item>
            <el-form-item style="margin-top:10px" label="备注" label-width="120" >
               <el-input v-model="reCar.returnCarRemark"  autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="reCar.state = false">取 消</el-button>
            <el-button type="primary" @click="returnCar">确 定</el-button>
          </div>
        </el-dialog>
  </div>
</template>
<script>
import modalForm from './modal-form.vue';
import modalDetail from './modal-detail.vue';
import pageCut from "@/component/pagination/index.vue";
import addressSelect from './address.vue';
export default {
  name:'apply',
  data(){
    return {
      id:'',
      filterForm:[
        { name: '乘/用车人', value: '', type: 'text' },
        { name: '用车状态', value: '', type: 'select', options:['全部','审批中','已驳回','派车中','已派车','已还车','已取消']}],
      tableData:[],
      dialogFormVisible: false, 
      dialogDetailVisible: false,
      pageSize: 10,
      pageNum: 1,
      total:1,
      options:{
        0:'未开始',
        1:'审批中',
        2:'已驳回',
        3:'派车中',
        4:'已派车',
        5:'已还车',
        6:'已取消'
      },
      userInfo:{},
      labAddress: { 
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
      },
      reCar:{
        state: false,
        id:"",
        numberPlates: "11",
        returnCar: "",
        detailAddress:'',
        returnCarRemark: ""
      }
    }
  },
  components:{
    'modal-form': modalForm,
    'modal-detail': modalDetail,
     pageCut,
     addressSelect
  },
  created(){
    this.getData();
    this.getUseInfo();
  },
  methods:{
       // 获取登录用户个人信息
    getUseInfo() {
        this.$fetchData
            .fetchPost({}, this.$fetchApi.pollingGetUserInfo.api, "json")
            .then(res => {
                if (res.code == "200") {
                    this.userInfo = res.result;
                }
            });
    },
    async getData() {
      const params = {
        keyWords:this.filterForm[0].value,
        approvalStatus:this.filterForm[1].value||'', 
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const api = this.$fetchApi.nanFanCarGetApplyList.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code === "200") {
        this.tableData = data.result.list || [];
        this.total = data.result.total
      }
    },
    addressInfoStart(val) {
      this.reCar.returnCar  = val.reduce(function(prev, cur) {
            return  prev + cur.name;
      },'') 
    },
    changeDetailAddressStart(val){
      this.reCar.detailAddress = val;
    },
    pageChange(num){
      this.pageNum =Number(num.page_index);
      this.getData();
    },
    getSearch:function(type) {
      if(type === '1'){
        this.filterForm.forEach(item => {
          item.value = "";
        })
      }
      this.pageNum = 1;
      this.getData()
    },
    addRecord:function() {

    },
    handleEdit(index,el,i){
      // 查看详情
      if( i === 1 ) {
        this.id = el.id;
        this.dialogDetailVisible = true;
      }else if( i === 2 ){
        // 取消用车
         this.$confirm('您确定要取消用车？', '取消用车', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.cancelApply(el.id);
        })
      }else if( i === 3 ){
        // 取消用车
        //  this.$confirm('您确定要还车吗？', '还车', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //  this.returnCar(el);
        // })
        this.reCar.id = el.id;
        this.reCar.numberPlates = el.numberPlates;
        this.reCar.state = true;
      }
    },
    //  取消申请
    async cancelApply(id) {
      const api = this.$fetchApi.nanFanCarApplyCancel.api;
      const data = await this.$fetchData.fetchPost({}, `${api}/${id}`, "json");
      if(data.code !== '200'){
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({
        message:'取消成功!',
        onClose:()=>{
         this.getSearch();
        }
      });
    },
    // 还车
    async returnCar(){
      if(this.reCar.returnCar == ""){
        //  || this.reCar.detailAddress == ""
        this.$message.error('请填写还车地址!')
        return false;
      }
      const api = this.$fetchApi.nanFanCarReturnCar.api;
      const param = {
        "id":this.reCar.id,
        "numberPlates": this.reCar.numberPlates,
        "returnCar": this.reCar.returnCar+this.returnCar.detailAddress,
        "returnCarRemark": this.reCar.returnCarRemark
      }
      this.reCar.state = false;
      const data = await this.$fetchData.fetchPost(param, api, "json");
      if(data.code !== '200'){
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({
        message:'还车成功!',
        onClose:()=>{
         this.getSearch();
        }
      });
    }

  }
}
</script>
<style lang="less" scoped>
.overflow-text{
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分文字以...显示*/
}
.reCar {
  .el-input{
    width: calc(100% - 100px);
    margin-right:10px
  }
}
.apply{
  background: rgba(249, 251, 255, 0.99);
  min-height: 400px;
  .filter-form{
    padding: 10px 0px 0px 10px;
  }
  &-content {
    box-sizing: border-box;
    padding: 0 20px;
    &-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-indent: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(51, 128, 254, 1);
        border-radius: 50%;
      }
    }
    /deep/ .el-table td {
      border: none;
      padding: 2px 0;
      // margin: 5px 0;
      // background-color:#fff;
      // box-sizing: border-box;
    }
    &-btn {
      text-align: center;
      height: 150px;
      line-height: 50px;
    }
    &-item {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      span {
        margin-right: 35px;
        font-size: 18px;
      }
      /deep/ .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      /deep/ .el-icon-arrow-down {
        font-size: 16px;
      }
    }
  }
  
}

</style>
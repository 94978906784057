import { render, staticRenderFns } from "./statistics.vue?vue&type=template&id=5d0c273b&scoped=true"
import script from "./statistics.vue?vue&type=script&lang=js"
export * from "./statistics.vue?vue&type=script&lang=js"
import style0 from "./statistics.vue?vue&type=style&index=0&id=5d0c273b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0c273b",
  null
  
)

export default component.exports
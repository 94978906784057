<template>
  <div class="manage">
    <div class="text-center">
      <el-radio-group v-model="applyState" style="margin-bottom: 30px;">
        <el-radio-button label="2">待处理的申请1</el-radio-button>
        <el-radio-button label="3">全部申请</el-radio-button>
      </el-radio-group>
    </div>
     <div class="nf-tabs-filter-form">
          <el-form :inline="true" class="filter-form">
            <el-form-item :label="item.name" v-for="(item,index) in filterForm" :key="index">
              <el-select class="form-row" v-model='item.value' :placeholder ="`请选择 ${item.name}`" v-if="item.type == 'select'">
                <el-option :label="o" :value="i" v-for="(o,i) in item.options" :key="i"></el-option>
              </el-select>
              
              <el-input v-else class="form-row" v-model="item.value" :placeholder="`请输入 ${item.name}`"></el-input>
            </el-form-item>
         
            <el-form-item>
              <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 100%;" empty-text="暂无申请记录">
          <el-table-column label width="10" align="left">
            <div
              style="width:3px;height:50px;margin-left:-10px;background:rgba(51,128,254,1);border-radius:2px;"
            ></div>
          </el-table-column>
          <el-table-column width="100" label="用车人" prop="carUser" align="center"></el-table-column>
          <el-table-column label="用车事由" prop="carCause" align="center"></el-table-column>
          <el-table-column label="用车时间" prop="startDate" align="center"></el-table-column>
          <el-table-column label="用车状态" align="center">
            <template slot-scope="scope">
              <div>
                {{options[scope.row.approvalStatus]}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-tickets"
                @click="handleEdit(scope.$index, scope.row,1)"
              >详情</el-button>
              <el-button
                size="mini"
                v-if="scope.row.approvalStatus === 1&&applyState =='2'"
                type="success"
                @click="handleEdit(scope.$index, scope.row,2)"
                icon="el-icon-edit"
              >审批</el-button>
              <el-button
                size="mini"
                v-if="scope.row.approvalStatus === 3&&applyState =='2'"
                type="success"
                @click="handleEdit(scope.$index, scope.row,3)"
                icon="el-icon-edit"
              >派车</el-button>
              <el-button
                size="mini"
                v-if="scope.row.registerStatus === 2"
                type="text"
                style="text-decoration:underline"
                @click="handleEdit(scope.$index, scope.row,4)"
              >请填写登记信息</el-button>
            </template>
          </el-table-column>
        </el-table>
        <page-cut :total="total" @pageChange="pageChange" :currentPage="pageNum" :pageSize="pageSize"></page-cut>
        <modal-detail v-model="dialogDetailVisible" :id="id" @refresh="getSearch" :showDetail= "showDetail" :isApproval = "isApproval" :isReturnCar="isReturnCar" />
        <!-- <modal-detail v-model="dialogDetailVisible" :id="id" @refresh="getSearch" :showDetail= "showDetail"  /> -->
  </div>
</template>
<script>
import modalDetail from './modal-detail'
import pageCut from "@/component/pagination/index.vue";
import newsVue from '../../home/news.vue';
export default {
  name:'apply',
  data(){
    return {
      filterForm:[
        { name: '用车人', value: '', type: 'text' },
        { name: '用车状态', value: '', type: 'select', options:['全部','审批中', '已驳回' , '派车中', '已派车', '已还车','已取消']}],
      tableData: [],
      applyState: 2,
      isApproval: false, // 审批人
      isReturnCar: false,
      showDetail: true,
      pageNum: 1,
      pageSize: 10,
      id:'',
      dialogDetailVisible: false,
      total: 1,
      options:{
        0:'未开始',
        1:'审批中',
        2:'已驳回',
        3:'派车中',
        4:'已派车',
        5:'已还车',
        6:'已取消'
      },
    }
  },
  components:{
    'modal-detail': modalDetail,
    pageCut
  },
  created(){
    //  查询用户的权限 1 审批者的权限  2 派车的权限
    const promise1 = this.getIsApproval();
    const promise2 =  this.getIsReturnCar();
    Promise.all([promise1,promise2]).then( () => {
      this.getData();
    })
    // this.getData();
  },
  methods:{
    onSubmit:function() {
      this.pageNum = 1;
      this.getData();
    },
    getSearch:function(type) {
      this.filterForm.forEach(item => {
        item.value = "";
      })
      this.id = "";
      this.pageNum = 1;
      this.getData()
    },
    pageChange(num){
      this.pageNum = Number(num.page_index);
      this.getData();
    },
    async getIsApproval() {
      const api = this.$fetchApi.nanFanCarisApproval.api;
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if(data.code === '200'){
        this.isApproval = data.result;
        console.log("this.isApproval4444444",this.isApproval)

      }
    },
    async getIsReturnCar() {
      const api = this.$fetchApi.nanFanCarisReturnCar.api;
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if(data.code === '200'){
        this.isReturnCar = data.result;
        console.log("this.isReturnCar555555",this.isReturnCar)
      }
    },
    async getData(){
      console.log("333",this.applyState)
       let api = ""
       if(this.applyState == '2'){
        api = this.$fetchApi.nanFanCarManageList.api
       }else{
        api = this.$fetchApi.nanFanCarManageAllList.api

       }
      // if(this.isApproval) {
      //   api = this.applyState == '2' ? this.$fetchApi.nanfanCarApprovalerList.api : this.$fetchApi.nanfanCarApprovalerALlList.api;
      // }
      // if(this.isReturnCar) {
      //   api = this.applyState == '2' ? this.$fetchApi.nanfanCarSenderList.api : this.$fetchApi.nanfanCarSenderAllList.api;
      // }
      // if(!api) return false;
      const param = {
         numberPlates: '',
         keyWords:this.filterForm[0].value,
         approvalStatus:this.filterForm[1].value || "",
         pageNum: this.pageNum,
         pageSize: this.pageSize
      }
      const data = await this.$fetchData.fetchPost(param, api, "json");
      if(data.code === '200'){
        if(data.result){
          this.tableData = data.result.list || [];
          this.total = data.result.total;
        }else{
          this.tableData =  [];
          this.total = 1;
        }
      }
    },
    handleEdit(index,el,i){
      // 查看详情
      this.showDetail = i === 1 ? true : false;  // 是否仅仅展示详情   为false 标识可以编辑审批
      this.id = el.id;
      this.dialogDetailVisible = true;
    },
    addRecord:function() {

    }
  },
  watch:{
    applyState(newValue){
      this.filterForm[1].value = "";
      this.onSubmit();
    }
  }
}
</script>
<style lang="less" scoped>
.text-center {
  text-align: center;
}
/deep/.el-radio-button__inner {
  width: 120px;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  border-color: #67c23a;
  background-color:#67c23a ;
}
/deep/.el-radio-button__inner:hover {
    color: #67c23a ;
}
.manage{
  padding-top: 20px;
  background: rgba(249, 251, 255, 0.99);
  min-height: 400px;
  .filter-form{
    padding: 10px 0px 0px 10px;
  }
  &-content {
    box-sizing: border-box;
    padding: 0 20px;
    &-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-indent: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(51, 128, 254, 1);
        border-radius: 50%;
      }
    }
    /deep/ .el-table td {
      border: none;
      padding: 2px 0;
      // margin: 5px 0;
      // background-color:#fff;
      // box-sizing: border-box;
    }
    &-btn {
      text-align: center;
      height: 150px;
      line-height: 50px;
    }
    &-item {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      span {
        margin-right: 35px;
        font-size: 18px;
      }
      /deep/ .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      /deep/ .el-icon-arrow-down {
        font-size: 16px;
      }
    }
  }
  
}

</style>